import type {
  LegacyPBB,
  LegacyPBBSegment,
} from "../../commonInterfaces/LegacyPlanner"
import type {
  CalculatedValuesForDisplayedTimeRange,
  EmployeeV3,
  EntryClassificationV3,
  EntryV3,
  OrganisationalUnitV3,
  PlannerV3,
} from "../../commonInterfaces/PlannerV3"
import type { PoolEntryOrigin } from "../../commonInterfaces/PoolEntryOrigin"
import { get, } from "../../shared/lib/requestUtils"
import getApplicableLimit from "../../shared/lib/retroactiveLimitsUtils/getApplicableLimit"
import type EditableLoggedEntry from "../calendar/EditableLoggedEntry"
import type { Break } from "./PlannedDay"
import PlannedDay from "./PlannedDay"

const DEFAULTDAYS = 4
const MERGEWAITTIMEOUT = 35000 // min 30 seconds for aggregator start, plus 5 buffer
const MERGECHECKINTERVAL = 500

export default class PlannerData {
  // TODO: Move this outside the scope of this (clean) module:

  public retroactiveLimit?: string
  private plannerData?: PlannerV3
  private startDate?: Date
  private endDate?: Date

  constructor(
    // private departmentId: string,
    private clientId: string,
    private employeeId: string,
    private locale: string,
    { startDate, endDate }: { startDate?: Date; endDate?: Date } = {}
  ) {
    this.startDate = startDate ?? new Date()
    this.endDate =
      endDate ??
      new Date(
        this.startDate.getFullYear(),
        this.startDate.getMonth(),
        this.startDate.getDate() + DEFAULTDAYS
      )
  }

  public getPlannerV3(): PlannerV3 | undefined {
    return this.plannerData
  }

  public hasHolidayOn(employeeId: string | undefined, departmentId: string, date: string): boolean {
    return employeeId !== undefined && !!this.plannerData
      ?.employeeCalendarsByOrganisationalUnit[departmentId]
      ?.find(
        employee => employee.id === employeeId
          && employee.entries.find(
            e2 => e2.isAllDay
              && e2.endDate
              && e2.startDate <= date
              && e2.endDate >= date
          )
      )
  }

  public hasHolidayOrLoggedDayOn(employeeId: string | undefined, departmentId: string, date: string): boolean {
    return employeeId !== undefined && !!this.plannerData
      ?.employeeCalendarsByOrganisationalUnit[departmentId]
      ?.find(
        employee => employee.id === employeeId
          && (
            employee.entries.find(
              e2 => e2.isAllDay
                && e2.endDate
                && e2.startDate <= date
                && e2.endDate >= date
            ) || employee.loggedEntries?.find(
              e => e.startDate === date
            )
          )
      )
  }

  public getAvailableDepartments(): OrganisationalUnitV3[] {
    return [...(this.plannerData?.availableOrganisationalUnits ?? [])]
  }

  public getAvailableEntryClassifications(
    deptId: string
  ): EntryClassificationV3[] {
    const d = this.findDepartment(
      deptId,
      this.plannerData?.availableOrganisationalUnits
    )
    return d?.availableClassifications ?? []
  }

  public hasData(): boolean {
    return !!this.plannerData?.employeeCalendarsByOrganisationalUnit
  }

  public async load(): Promise<void> {
    const valid = !!(this.employeeId && this.clientId)
    const fromDate = valid ? this.getFromDateForLoadPlanner() : undefined
    const toDate = valid ? this.getToDateForLoadPlanner() : undefined
    const limit = await getApplicableLimit('planners')
    this.retroactiveLimit = limit?.notAllowedBefore
    if (valid && fromDate !== undefined && toDate !== undefined) {
      const url = "/rest/calendarES/loadPlanner"
      this.plannerData = await get(
        url,
        {
          contextClientId: this.clientId,
          fromDate,
          toDate,
          mobile: 'true'
        }
      )
      this.addLoadedPoolFlags()
    }
  }

  /**
   * NOTE: You *must* first combine the pool entries and existing entries
   * from the currently loaded planner (in the `entries` parameter)
   */
  public async returnOneToPool(
    date: string,
    identifier: string
  ): Promise<boolean> {
    const url = "/rest/planner/returnSingleMobileActivityToPool"
    try {
      const r = await fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({ async: true, date, identifier }),
      })
      const json = await r.json()
      if (json.success === false) {
        return false
      }
    } catch (e) {
      console.error(
        "An error occurred when returning a shift:",
        e
      )
      return false
    }
    return true
  }

  /**
   * NOTE: You *must* first combine the pool entries and existing entries
   * from the currently loaded planner (in the `entries` parameter)
   */
  public async returnAllToPool(
    date: string,
    entries: EditableLoggedEntry[],
    supersedingEntry: EntryV3
  ): Promise<boolean> {
    const url = "/rest/planner/returnMobileActivityDayToPool"
    const pbb = makePBB(this.employeeId, date, entries, undefined, true, supersedingEntry)
    try {
      const r = await fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify(pbb),
      })
      const json = await r.json()
      if (json.success === false) {
        return false
      }
    } catch (e) {
      console.error(
        "An error occurred when returning a day:",
        e
      )
      return false
    }
    return true
  }

  /**
   * NOTE: You *must* first combine the pool entries and existing entries
   * from the currently loaded planner (in the `entries` parameter)
   */
  public async mergeFromPool(
    date: string,
    entries: EditableLoggedEntry[],
  ): Promise<boolean> {
    const url = "/rest/planner/mergeActivityDayFromPool"
    const pbb = makePBB(this.employeeId, date, entries, undefined, true)
    try {
      const r = await fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify(pbb),
      })
      const json = await r.json()
      if (json.success === false) {
        return false
      }
      return await this.waitForMergeResult(<string>json.correlationId)
    } catch (e) {
      console.error(
        "An error occurred when merging a planned day with pool entries:",
        e
      )
      return false
    }
  }

  public async planLogged(
    date: string,
    entries: EditableLoggedEntry[],
    message: string
  ): Promise<boolean> {
    const url = "/rest/daylogger/logDayAsEmployee"
    const pbb = makePBB(this.employeeId, date, entries, message)
    try {
      await fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify(pbb),
      })
    } catch (e) {
      console.error("An error occurred when saving a logged day:", e)
      return false
    }
    return true
  }

  public async emptyLogged(date: string): Promise<boolean> {
    const url = "/rest/daylogger/emptyDayAsEmployee"
    try {
      await fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({ date, userId: this.employeeId }),
      })
    } catch (e) {
      console.error("An error occurred when emptying a logged day:", e)
      return false
    }
    return true
  }

  public async deleteLogged(date: string): Promise<boolean> {
    const url = "/rest/daylogger/deleteDayAsEmployee"
    try {
      await fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({ async: true, date, userId: this.employeeId }),
      })
    } catch (e) {
      console.error("An error occurred when deleting a logged day:", e)
      return false
    }
    return true
  }

  public getDays(): PlannedDay[] {
    const res: PlannedDay[] = []
    this.iterDays((date, dateString) => {
      const { employee, departmentId } = this.findUser(dateString) // TODO: THE DAY IS RELEVANT HERE!
      if (employee && departmentId) {
        const entries = employee?.entries ?? []
        const loggedEntries = employee?.loggedEntries ?? []
        res.push(
          new PlannedDay(
            dateString,
            entries.filter(
              e => e.startDate === dateString
                || (
                  e.isAllDay
                  && e.startDate < dateString
                  && e.endDate! >= dateString
                )
            ),
            loggedEntries.filter(e => e.startDate === dateString),
            this.plannerData!,
            employee, // TODO: Verify!
            departmentId, // TODO user ID + date?
            this.locale
          )
        )
      }
    })
    return res
  }

  public getEverySingleDay(): {
    date: Date
    dateString: string
    plannedDay?: PlannedDay | null
    holiday?: string
  }[] {
    const plannedDays = this.getFullDayMapping()
    const res: {
      date: Date
      dateString: string
      plannedDay?: PlannedDay
      holiday?: string
    }[] = []
    this.iterDays((date, dateString) => {
      const plannedDay = plannedDays[dateString]
      const departmentId = plannedDay?.department?.id
      res.push({
        date,
        dateString,
        plannedDay,
        holiday: departmentId
          ? this.findDepartment(
            departmentId,
            this.plannerData?.availableOrganisationalUnits
          )?.holidays?.find(h => h.date === dateString)?.name
          : undefined,
      })
    })
    return res
  }

  public getCalculated(): CalculatedValuesForDisplayedTimeRange | undefined {
    if (!this.startDate) {
      return undefined
    }
    const m = `${this.startDate?.getMonth() + 1}`.padStart(2, "0")
    const d = `${this.startDate?.getDate()}`.padStart(2, "0")
    const date = `${this.startDate?.getFullYear()}-${m}-${d}`
    const { employee } = this.findUser(date)
    if (employee) {
      return employee.calculatedValues
    } else {
      return undefined
    }
  }

  private waitForMergeResult(correlationId: string): Promise<boolean> {
    const timeout = new Date((new Date()).getTime() + MERGEWAITTIMEOUT).getTime()
    return new Promise((resolve, reject) => {
      const check = async () => {
        try {
          const { result } = await get<{ result: boolean }>(
            '/rest/planner/hasAsyncRequestResolved',
            {
              correlationId,
              aggregatorName: 'activityplanner'
            }
          )
          if (result) {
            resolve(true)
          } else if ((new Date()).getTime() > timeout) {
            resolve(true)
          } else {
            window.setTimeout(() => void check(), MERGECHECKINTERVAL)
          }
        } catch (e) {
          reject(e as Error)
        }
      }
      window.setTimeout(() => void check(), MERGECHECKINTERVAL)
    })
  }

  private iterDays(fun: (date: Date, dateString: string) => void) {
    if (this.startDate && this.endDate) {
      let cursor = this.getNulledJSDate(this.startDate)
      const end = this.getNulledJSDate(this.endDate)
      while (cursor.getTime() < end.getTime()) { // <: Skip last day
        const dateString = this.getDateString(cursor)
        fun(cursor, dateString)
        cursor = this.incDay(cursor)
      }
    }
  }

  private findUser(date: string): {
    employee?: EmployeeV3
    departmentId?: string
  } {
    let employee: EmployeeV3 | undefined
    let departmentId: string | undefined
    const calendars =
      this.plannerData?.employeeCalendarsByOrganisationalUnit ?? {}
    for (const deptId of Object.keys(calendars)) {
      // TODO: build more responsive data structure for plannerData in constructor (performance)
      const cal = calendars[deptId]
      employee = cal.find(
        e =>
          `${e.id}` === `${this.employeeId}` &&
          e.relationshipToOrganisationalUnit.find(
            r =>
              r.date === date &&
              (r.name === "assigned" || r.name === "temp-assigned")
          )
      )
      if (employee) {
        departmentId = deptId
        break
      }
    }
    return { employee, departmentId }
  }

  private findDepartment(
    departmentId: string,
    roots?: OrganisationalUnitV3[]
  ): OrganisationalUnitV3 | undefined {
    let result
    for (const r of roots ?? []) {
      if (`${r.id}` === departmentId) {
        result = r
      }
      if (!result) {
        result = this.findDepartment(departmentId, r.children)
      }
      if (result) {
        break
      }
    }
    return result
  }

  private getFullDayMapping(): {
    [dateString: string]: PlannedDay
  } {
    const res: Record<string, PlannedDay> = {}
    for (const d of this.getDays()) {
      const endDate = d.getJSEndDate()
      if (endDate !== null) {
        for (const date of this.datesBetweenAsArray(d.getJSDate(), endDate)) {
          res[this.getDateString(date)] = d
        }
      } else {
        res[this.getDateString(d.getJSDate())] = d
      }
    }
    return res
  }

  private getFromDateForLoadPlanner(): string | undefined {
    return this.startDate !== undefined
      ? this.getDateString(this.startDate)
      : undefined
  }

  private getToDateForLoadPlanner(): string | undefined {
    return this.endDate !== undefined
      ? this.getDateString(this.endDate)
      : undefined
  }

  private getDateString(d: Date) {
    const year = d.getFullYear()
    const month = `${d.getMonth() + 1}`.padStart(2, "0")
    const day = `${d.getDate()}`.padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  private getNulledJSDate(d: Date) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0)
  }

  private incDay(d: Date) {
    return this.getNulledJSDate(
      new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() + 1,
        d.getHours(),
        d.getMinutes(),
        d.getSeconds(),
        d.getMilliseconds()
      )
    )
  }

  private datesBetweenAsArray(date1: Date, date2: Date): Date[] {
    let cursor = this.getNulledJSDate(date1)
    const end = this.getNulledJSDate(date2)
    const res = []
    while (cursor.getTime() <= end.getTime()) {
      res.push(cursor)
      cursor = this.incDay(cursor)
    }
    return res
  }

  private addLoadedPoolFlags(): void {
    for (const employees of Object.values(
      this.plannerData?.employeeCalendarsByOrganisationalUnit ?? {}
    )) {
      for (const employee of employees) {
        for (const e of [...employee.entries, ...employee.supersededEntries]) {
          if (e.poolReference) {
            e.poolReference.loadedFromBackend = true
          }
        }
      }
    }
  }

}

function makePBB(
  userId: string,
  date: string,
  entries: EditableLoggedEntry[],
  message?: string,
  dropDepartmentId?: boolean,
  supersedingEntry?: EntryV3
): Partial<LegacyPBB> & {
  async: true,
  isSuperseded: boolean,
  returned?: PoolEntryOrigin
} {
  if (entries.length === 0) {
    throw new Error("cannot save empty day")
  }
  const e0 = entries[0].getEntryV3()
  const pbb: Partial<LegacyPBB> = {
    id: e0.dayId,
    name: e0.name,
    abbrv: e0.abbreviation,
    userId,
    date,
    isPartOfAnAutoplan: false,
    isLoggedTime: true,
    isPartOfMultiDayTimespan: false,
    multiDayTimespanId: undefined,
    multiDayTimespanStartDate: undefined,
    multiDayTimespanEndDate: undefined,
    segments: entries.map(
      e => makeSegment(e, dropDepartmentId)
    ),
    message,
  }
  return {
    ...pbb,
    async: true,
    isSuperseded: supersedingEntry !== undefined,
    returned: userId !== undefined && supersedingEntry !== undefined ? {
      employee: {
        id: userId
      },
      supersededBy: supersedingEntry.entryClassification ? {
        ...supersedingEntry.entryClassification
      } : undefined
    } : undefined,
  }
}

const makeSegment = (
  entry: EditableLoggedEntry,
  dropDepartmentId: boolean = false,
): LegacyPBBSegment => {
  const e = entry.getEntryV3()
  const startTime = entry.getStartTime()
  const endTime = entry.getEndTime()
  const relativeday = guessRelativeDay(startTime!, endTime!)
  const orgunitId = e.organisationalUnitIdForThisEntry
  const cls = e.entryClassification
  return {
    poolReference: e.poolReference,
    // TODO: add origin here once employees can push their own shifts
    startTime: makeRelativeTime(startTime!),
    endTime: makeRelativeTime(endTime!, relativeday),
    allDay: !!e.isAllDay,
    orgunitId: (
      (dropDepartmentId && e.poolReference && !e.poolReference.loadedFromBackend)
        || orgunitId === "" ? undefined : orgunitId
    ) ?? null, // TODO: department in Entry!
    calendarentrytypeId: cls?.id ?? null,
    color: cls?.color,
    name: e.name ?? '',
    abbrv: e.abbreviation ?? '',
    breaks: entry.getBreaks().map(makeBreak),
  }
}

const makeBreak = (b: Break) => {
  const durationInMinutes = b.getDurationInMinutes() ?? 0
  const durationInSeconds = durationInMinutes * 60
  const startTime = b.getStartTime()
  const endTime = b.getEndTime()
  const relativeday = guessRelativeDay(startTime!, endTime!)
  return {
    startTime: makeRelativeTime(startTime!),
    endTime: makeRelativeTime(endTime!, relativeday),
    durationInMinutes,
    durationInSeconds,
    duration: durationInSeconds,
    _protected_duration: durationInSeconds,
    allDay: false,
  }
}

const guessRelativeDay = (t1: string, t2: string): number => {
  if (!t1 || !t2) {
    return 0 // unsure about !t2
  } else {
    return t2 < t1 ? 1 : 0
  }
}

const makeRelativeTime = (t: string, relativeday: number = 0) =>
  t
    ? {
      type: "RelativeTime",
      ...getHourAndMinute(t),
      relativeday,
    }
    : {
      // workaround for backend to cope with "proper" all-day entries
      type: "RelativeTime",
      hour: 0,
      minute: 0,
      relativeday,
    }

const getHourAndMinute = (t: string): { hour: number; minute: number } => {
  const [hour, minute] = t.split(":").map(el => parseInt(el, 10))
  return { hour, minute }
}
